<template>
  <wrapper>
    <portal-back router-name="info-index" />

    <h1 class="font-bold text-2xl mb-4">Quizzes</h1>

    <div v-for="category in categories" :key="category" class="mb-8">
      <h3 class="font-bold mb-2">{{ category }}</h3>

      <quiz-item
        v-for="quiz in quizzes[category]"
        :key="quiz.uuid"
        :quiz="quiz"
      />
    </div>
  </wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import QuizItem from "components/Quiz/Item.vue";

export default {
  name: "Index.vue",

  components: {
    QuizItem
  },

  mounted() {
    this.$store.dispatch("quizzes/initializeQuizzes");
  },

  computed: {
    ...mapGetters("quizzes", ["categories", "quizzes"])
  }
};
</script>

<style scoped></style>
