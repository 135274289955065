<template>
  <router-link :to="{ name: 'quiz-detail', params: { uuid: quiz.uuid } }">
    <div
      class="flex items-center mb-2 justify-between w-full bg-white rounded-lg p-2 text-black"
    >
      <div>
        <h4 class="font-bold mb-2 ">{{ quiz.title }}</h4>

        <quiz-time :time="quiz.time" />
      </div>

      <icon-forward class="w-5 h-5" />
    </div>
  </router-link>
</template>

<script>
import IconForward from "assets/icons/forward.svg";
import QuizTime from "components/Quiz/Time.vue";

export default {
  name: "Item",
  props: {
    quiz: {
      type: Object
    }
  },

  components: {
    IconForward,
    QuizTime
  }
};
</script>

<style scoped></style>
